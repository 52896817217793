#pnlSubpage_settings {
    .gutter-8();

    .button-mapper(~"button.btn-help", question-circle);
    .btn-help {
        vertical-align: middle;
        font-size: 16px;
        padding: 2px;

        border: 1px solid transparent;
        border-radius: 4px;

        color: @brand-primary;

        &:hover,
        &:focus {
            text-decoration: none;
            border-color: @link-hover-color;
            color: @link-hover-color;
        }
    }

    .modal {
        font-size : 16px;
        line-height : 1.1;
    }

    .audio-control {
        margin-top: 5px;

        > div {
            height: 24px;
            display: flex;
            align-items: center;

            > span {
                padding-right: 8px;
            }
        }

        .button-mapper(~".btn", play-circle);
        &.playing {
            .button-mapper(~".btn", stop-circle);
        }
    }
}
