.panel.panel-icon {
    .panel-icon-body {
        display : table;

        .icon {
            display : table-cell;
            vertical-align : middle;
            padding-right : 10px;
            font-size : 40px;

            .tbicon();
        }

        .content {
            display : table-cell;
            vertical-align : middle;
        }
    }

    &.panel-primary .panel-body .icon:before {
        color : @panel-primary-border;
        .kvp(@tbfontglyphmap, info-circle);
        content : @kvp;
    }

    &.panel-danger .panel-body .icon:before {
        color : @panel-danger-border;
        .kvp(@tbfontglyphmap, alert-circle);
        content : @kvp;
    }
}
