@import "functions/kvp"; // used to lookup tbfont glyph encodings

.form-control {
    &:disabled {
        color : @color-gray;
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color : #555;
    }
}

select.form-control {
    appearance: none;

    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 1em;
    background-origin: padding-box;
    background-image: data-uri('image/svg+xml;base64', '../fonts/glyphs/menu_down_right_padded.svg');

    padding-right: 24px;

    &::-ms-expand {
        display: none;
    }

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

// customized checkbox

input[type="checkbox"] {
    position : absolute;
    margin-left : -999em;
    width : 100%;
    opacity : 0;

    & + label {
        font-weight : inherit;

        &:before {
            cursor : pointer;
            vertical-align : text-top;
            width : 16px;
            height : 16px;
            display : inline-block;
            border : 1px solid @color-base;
            border-radius : 3px;
            font-family : 'tbfont';
            content : '\a0';
            font-size : 8px;
            padding : 3px;
            margin-right : 5px;
        }

        .form-horizontal & {
            margin-top : 5px;
            margin-bottom : 1px;
        }

        .form-inline & {
            margin-top : 0;
            margin-bottom : 1px;
        }
    }

    &:focus {
        & + label:before {
            border-color : @color-input-border-focus;
            .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-input-border-focus}");
            color : balls;
        }
    }


    &:checked {
        & + label:before {
            border-color : @brand-primary;
            .kvp(@tbfontglyphmap, check);
            content : @kvp;
            background-color : @brand-primary;
            color : white;
        }
    }

    &:disabled {
        & + label:before {
            background-color : @input-bg-disabled;
            cursor : default;
            border-color : @input-border;
        }
    }
}

// braintree hosted fields

.form-control.braintree-hosted-fields {
    // add focus style to braintree hosted fields

    &.braintree-hosted-fields-focused {
        .tb-form-control-focus();
    }

    // fix text positioning
    padding-top : 3px;
    padding-bottom : 1px;
}

.form-section {
    margin-top : 20px;
}

.form-control-feedback {
    padding-right : 8px;
    top : 35px !important;

    &.required {
        padding-top : 7px;
        font-weight : bold;
        font-size : 30px;
        color : @input-border;
    }
}

.control-label {
    font-size : 16px;

    .form-horizontal & {
        text-align : right;
    }
}

.form-horizontal .form-group {
    .note {
        display : inline-block;
        padding-top : 4px;
    }
}

.form-horizontal {
    .form-control-feedback {
        top : 0 !important;
    }
}

// datepicker input group button
.input-group-btn.datepicker {
    .tbicon();

    button {
        &:before {
            .kvp(@tbfontglyphmap, calender);
            content : @kvp;
        }
    }
}

.input-group-file {
    input[type=file] {
        display : none;
    }
}
