.loading-indicator-parent {
    position: relative;

    .loading-indicator {
        display: none;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        align-items: center;

        .loading-indicator-img {
            display: inline-block;
            margin-right: 10px;
        }
    }

    &.loading-indicator-active {
        > * {
            visibility: hidden;
        }

        .loading-indicator {
            display: flex;
            visibility: visible;
        }
    }
}
