@lcm-width : 1024px;

html, body {
    height      : 100%;

    @media print {
        height : inherit;
    }
}

.load-error {
    width                   : @lcm-width;
    margin                  : 40px auto;
}

.portal-container {
    width                   : @lcm-width;
    margin                  : 0 auto;
    border-left             : 2px solid black;
    border-right            : 2px solid black;
    min-height              : 100%;

    @media print {
        border : 0 !important;
    }

    .app-main {
        .portal-loading {
            margin: 10px auto;
        }
    }
}

.app-header {
    .print-hide();

    display: flex;

    min-height : 85px;
    padding-left: 8px;
    padding-right: 15px;

    .logo-container {
        margin-top: 8px;
    }

    @media print {
        display : none !important;
    }

    .info-table-container {
        margin: 8px 0 0 22px;
    }
}

.subpage {
    min-height: 290px;
    margin-bottom : 10px;

    .subpage-table {
        margin-bottom: 10px;
    }
}

.bridge-info-table {
    display: flex;

    white-space: nowrap;

    ._name {
        &:after {
            // adds &nbsp; so that <td> does not shrink when the cell
            // is empty
            content: '\0000a0';
        }
        max-width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.info-table-container {
    table {
        .cell-title {
            font-weight : bold;
            font-size : 18px;
            color : @brand-primary;
        }

        td {
            padding : 3px;
        }

        .cell-data {
            font-weight : bold;
        }
    }

    &.info-table-container-padded {
        table td.cell-data {
            &:not(:last-child) {
                padding-right: 3rem;
            }
        }
    }
}

.login-control {
    margin: 0 15px;

    .panel {
        margin: 40px auto;
        width: 500px;
    }
}

#pnlLoginInfo {
    .print-hide();

    text-align : right;
    font-size : 12px;

    @media print {
        display : none !important;
    }
}

#pnlSubpage_addressBook, #pnlSubpage_users {
    .button-mapper(~"button.btnEdit", edit);
    .button-mapper(~"button.btnDelete", trash);

    .btn-add {
        min-width : 60px;
    }

    .column-name {
        max-width : 300px;
        overflow : hidden;
        text-overflow : ellipsis;
    }

    .column-userID {
        max-width : 100px;
        overflow : hidden;
        text-overflow : ellipsis;
    }
}

#pnlSubpage_info {
    .info-table-container {
        margin-right: 30px;
    }

    .note {
        padding : 3px;
        margin-top : 10px;
    }
}

body.view-ro {
    #pnlLoginInfo {
        display : none !important;
    }

    .bridge-info-table {
        .tr-pin {
            display : none !important;
        }
    }

    .navigation-toolbar {
        .rw-tab {
            display : none;
        }
    }
}

body.hide-navigation {
    .navigation-toolbar {
        display : none !important;
    }
}

body.hide-webCall {
    .webCallElement {
        display : none !important;
    }
}
