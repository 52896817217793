.print-header-overlay {
    .print-header-overlay-inner {
        width: @lcm-width;
        margin: 10px auto;
        padding: 0 17px;
    }
}

#pnlSubpage_report {
    .form-inline {
        margin-bottom : 5px;
    }

    .report-form {
        .print-hide();

        .fg-startedDate, .fg-endedDate {
            input {
                width : 120px;
            }
        }
    }

    .subpage-table {
        .btn-conf-details {
            .set-glyph(details);
        }

        .btn-edit-reference {
            .set-glyph(comment);
        }

        .btn-recording {
            .btn-recording-inner {
                .set-glyph(play-circle);
                display: flex;
            }

            .playback-options {
                display: none;
                font-size: 10px;

                > span {
                    display: block;
                }
            }

            &.state-default, &.state-selected, &.state-passcode {
                .playback-options {
                    display: initial;
                }
            }

            &.state-default, &.state-selected {
                .btn-recording-inner {
                    .set-glyph(play-circle-inverse);
                }
            }

            .selection-type {
                .set-glyph(blank);
            }

            &.state-default {
                background-color: @brand-primary;
                color: #fff;

                .selection-type {
                    .set-glyph(star-checked);
                }
            }

            &.state-selected {
                .selection-type {
                    .set-glyph(check);
                }
            }

            .passcode {
                .set-glyph(blank);
            }

            &.state-passcode {
                .passcode {
                    .set-glyph(lock);
                }
            }
        }

        .comment {
            max-width : 970px;
            overflow : hidden;
        }
    }
}

#pnlSubpage_confDetails {
    .column-callerID {
        max-width : 370px;
        overflow : hidden;
        text-overflow : ellipsis;
    }

    .caller-name {
        max-width : 370px;
        overflow : hidden;
        text-overflow : ellipsis;
    }
}

.subpage-report {
    .conf-details-header {
        display: flex;
        margin-bottom : 5px;

        .logo-container {
            .print-only();
        }
    }

    .print-header {
        display: none;
        body.body-print & {
            display: flex;
        }

        margin-bottom: 10px;
    }

    body.body-print & {
        margin-top: @print-header-overlay-height;

        @media print {
            margin-top: 0;
        }
    }
}
