.navigation-toolbar {
    .clearfix();
    .print-hide();

    margin-bottom: 10px;

    background-color      : @brand-primary;
    border-top : 1px solid black;

    @media print {
        display : none !important;
    }

    ul.tab-group {
        padding : 0;
        margin : 0;

        &.tab-group-right {
            float : right;
        }

        li {
            list-style            : none;
            float                 : left;
            top                   : 0;
            white-space           : nowrap;
            border                : 1px solid @brand-primary;
            border-bottom         : 1px solid black;

            margin-left           : 2px;
            margin-right          : 2px;
            height                : 22px;

            &.switcher-tab {
                .border-top-radius(@btn-border-radius-base);

                &:focus, &:hover {
                    outline               : none;
                    border                : 1px solid @color-primary;
                    background-color      : @color-primary-darkest;
                }
            }

            &.switcher-tab-active {
                background-color      : #ffffff !important;
                border                : 1px solid black;
                border-bottom-color   : #ffffff !important;

                a {
                    cursor                : default;
                    color                 : #000000;
                }
            }

            a {
                float                 : left;
                padding               : 4px 8px;
                color                 : #ffffff;
                text-decoration       : none;

                font-weight           : bold;

                outline-color         : transparent;
            }
        }
    }
}
