@wallet-keypad-color1 : #000;
@wallet-keypad-color2 : #fff;

#pnlSubpage_wallet {
    margin    : 0 120px;

    .wallet-container {
        position : relative;
        height : 590px;
    }

    @media print {
        .btn-print-confirm, .btn-back {
            display       : none;
        }
    }

    .pnlWallet {
        position : absolute;
        top : 0;

        width          : 325px;

        .logo {
            margin: 8px 0;
            text-align: center;

            img {
                width          : 200px;
                height         : 45px;
            }
        }

        .section {
            width          : 312px;
            border         : solid 1px black;
        }

        .note {
            font-size : 12px;
            margin : 6px 8px;
        }

        .keypad-commands {
            font-size : 11px;

            table {
                margin-top : 2px;

                td.description {
                    padding-left : 10px;
                }
            }

            .keypad-key {
                font-family : tbfont;

                display : inline-block;
                height : 18px;
                width : 20px;
                margin : 0 2px 1px 1px;

                line-height : 12px;

                font-size : 14px;
                text-align : center;

                border : 2px solid @wallet-keypad-color1;
                border-radius : 5px;
            }

            .participant .keypad-key {
                background-color : @wallet-keypad-color1;
                color : @wallet-keypad-color2;
            }
        }

        &.col1 {
            left : 0;
        }

        &.col2 {
            right : 0;
        }
    }
}
