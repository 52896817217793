.set-table-row-height(@height) {
    tbody tr {
        height : @height;
    }

    td.hover-cell {
        > button {
            height : @height;
        }
    }
}

.table-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom : 5px;

    .fg-table-search {
        .table-search {
            width : 120px;

            & + .input-group-btn button {
                .tbicon();
                .set-glyph(search);
            }
        }
    }
}

th {
    text-align: left;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-spacing: 0;

  th,
  td {
      border : 1px solid @panel-default-border;

      @media print {
          font-size : 12px;
      }
  }

  thead {
      th {
          font-weight: bold;
          padding : 5px 9px;
          color : @brand-primary;
          background-color : @panel-default-heading-bg;

          &.sorting, &.sorting_asc, &.sorting_desc {
              padding-right : 20px;
              .set-glyph(sort-unsorted);

              &:before {
                  font-family : tbfont;
                  float : right;
                  position : relative;
                  left : 14px;
                  color : @color-gray-light;
              }

              &:hover, &:focus, &:active {
                  outline : @color-btn-primary-focus-hover-border solid 2px;
              }
          }

          // active state
          &.sorting_asc, &.sorting_desc {
              &:before {
                  color: @brand-primary;
              }
          }

          &.sorting_asc {
              .set-glyph(sort-asc);
          }

          &.sorting_desc {
              .set-glyph(sort-desc);
          }
      }

      .sorting,
      .sorting_asc,
      .sorting_desc,
      .sorting_asc_disabled,
      .sorting_desc_disabled {
          cursor: pointer;
      }
  }

  tbody {
      th,
      td {
          padding: 6px;
      }

      tr {
          background-color: #ffffff;
      }

      tr.odd {
          background-color : @color-btn-primary-bg;
      }
  }

  &.nowrap th, &.nowrap td {
      white-space: nowrap;
  }

  td.hover-cell {
      padding : 0 !important;

      > button, > .label-only {
          width: 100%;
          padding: 6px;

          overflow: hidden;
      }

      > button {
          border: 0;
          background-color : transparent;
          .box-shadow(none);

          &:not(:disabled) {
              &:hover, &:focus, &.open {
                  outline : @color-btn-primary-focus-hover-border solid 2px;
              }
          }

          &.btn-table-icon {
              font-size: 20px;
              font-family: tbfont;
              color: @brand-primary;
          }
      }

      &.context-menu {
          > button {
              .content {
                  display: block;
                  overflow: hidden;
              }
          }
      }

      &.text-left {
          > button {
              text-align: left;
          }
      }
  }
}
