.btn-toolbar-discrete > .btn {
    min-width: 60px;
}

.btn-toolbar-discrete2 {
    display: flex;

    > * + * {
        margin-left: 3px;
    }

    > .btn {
        min-width: 60px;
    }
}

.button-mapper(~"button.btnMakeCall.btn-primary", make-call);
.button-mapper(~"button.btnMakeCall.btn-table-icon", make-call);
.button-mapper(~"button.btnPlay"   , play-circle);
.button-mapper(~"button.btnStop"   , stop-circle);

.button-mapper(~"#btnEndConference", cross-circle);
.button-mapper(~"#btnEndConference.subConf", conf-merge);
