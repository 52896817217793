@webcall-button-height : 36px;

@import "functions/kvp";

@meter-bar-dim-opacity: 0.40;

.webCallButton(@name, @glyphon, @glyphoff:undefined) {
    .@{name} .tbicon:before {
        .kvp(@tbfontglyphmap, @glyphon);
        content : @kvp;
    }

    .test-args() when not (@glyphoff = undefined) {
        .@{name}.toggled .tbicon:before {
            .kvp(@tbfontglyphmap, @glyphoff);
            content : @kvp;
        }
    }

    .test-args();
}

.webCallButtonDisabled(@name, @glyphon, @glyphoff:undefined) {
    .@{name}[disabled] .tbicon:before {
        .kvp(@tbfontglyphmap, @glyphon);
        content : @kvp;
    }

    .test-args() when not (@glyphoff = undefined) {
        .@{name}.toggled[disabled] .tbicon:before {
            .kvp(@tbfontglyphmap, @glyphoff);
            content : @kvp;
        }
    }

    .test-args();
}

[class*="tbicon"] {
    font-size : 20px;
}

.btn.btn-primary.toggled {
    .btn-primary-toggled();
}

.webCallButton(btnHold,unhold,hold);
.webCallButton(btnMute,mic,mic-off);
.webCallButton(btnSettings,settings);
.webCallButton(btnOpenDialpad,dialpad);

// conf buttons
.webCallButton(btnHand,hand,hand-selected);
.webCallButton(btnLock,conf-unlock,conf-lock);
.webCallButton(btnChangeRole,host);
.webCallButton(btnEndConference,cross-circle);
.webCallButton(btnRecording,recording-off,recording);
.webCallButton(btnMusic,music-off,music);
.webCallButtonDisabled(btnMute,mute-locked-unmuted,mute-locked-muted);

.webCallButton(btnDisconnect,call-end);


// player
.webCallButton(btnTogglePause,hold);
.btnTogglePause.play .tbicon:before {
    .kvp(@tbfontglyphmap, play);
    content : @kvp;
}

.input-meter-gauge, .output-meter-gauge {
    .progress-bar {
        transition : none;
    }
}

.disconnected-menu {
    .row {
        text-align : center;
        margin-bottom : 5px;
    }
}

.connected {
    .meters {
        margin-bottom : 4px;
        text-align : center;

        .gutter-4();

        .output-gain-slider {
            margin-top : 2px;
            margin-bottom : 4px;
        }

        .tbicon-volume-up {
            padding-bottom: 4px;
        }

        .input-meter-gauge {
            margin-top : 2px;
        }

        .output-meter-gauge {
            margin-top : 6px;
        }

        .muted, .hold {
            height : 18px;
        }

        .quality {
            .btn {
                width: 100%;
                height : 28px;
                margin-top : 8px;

                span {
                    opacity: 1;
                }

                &.meter-3 {
                    .tbicon-meter-bar-4 {
                        opacity: @meter-bar-dim-opacity;
                    }
                }

                &.meter-2 {
                    .tbicon-meter-bar-3, .tbicon-meter-bar-4 {
                        opacity: @meter-bar-dim-opacity;
                    }
                }

                &.meter-1 {
                    .tbicon-meter-bar-2, .tbicon-meter-bar-3, .tbicon-meter-bar-4 {
                        opacity: @meter-bar-dim-opacity;
                    }
                }

                &.meter-0 {
                    span {
                        opacity: @meter-bar-dim-opacity;
                    }
                }
            }
        }
    }

    // embedded button toolbar
    > .btn-group {
        width: 100%;

        .btn {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .player-controls {
        .gutter-8();
        margin-bottom : 5px;

        .input-meter-gauge {
            margin-top : 7px;
        }
    }

    .player-status {
        margin-bottom : 3px;
    }
}

.settings {
    .input-meter-gauge {
        margin-top: 3px;
    }
}

.btn-panel {
    // change gutter size to 4px for this div
    .gutter-4();

    .row {
        margin-bottom : 4px;
    }

    .btn {
        height : @webcall-button-height;
        width  : 100%;

        .tbicon {
            display : block;
        }

        .digit {
            display : block;
            font-family : 'tbfont';
            margin-bottom : 4px;
        }

        .alpha {
            display : block;
            font-size : 10px;
        }
    }
}

.disable-backdrop {
    z-index : (@zindex-modal + 10);
}

.audioLoadState {
    font-weight : bold;
    margin-bottom : 5px;
}

.qualityMeter {
    .score {
        font-size: 20px;
        font-weight: bold;
    }

    table {
        tr td:first-of-type {
            font-weight: bold;
            width : 80px;
        }
    }
}
