.thumb-common() {
    appearance: none;
    height: @slider-handle-height;
    width: @slider-handle-width;
	background-color: @slider-handle-color;
    border-radius: @input-border-radius;
    border: none;
}

.track-common() {
    height: @progress-slider-height;
    background: @progress-bg;
    border-radius: @progress-border-radius;
    border: none;
}

input[type=range] {
    appearance: none;
    height: @slider-handle-height;

    margin: 0;
    padding: 0;

    cursor : pointer;

    background: transparent;
    color: @progress-bar-bg;
    outline: none;

    user-select: none;
    touch-action: manipulation;

    &:focus,
    &.focus {
    }

    &::-moz-focus-outer {
        border: 0;
    }

    // Thumb

    &::-webkit-slider-thumb {
        .thumb-common();
        margin-top: ((@progress-slider-height - @slider-handle-height) * 0.5);
    }

    &::-moz-range-thumb {
        .thumb-common();
    }

    &::-ms-thumb {
        .thumb-common();
        margin: 0;
    }

    // Track

    &::-webkit-slider-runnable-track {
        .track-common();
        background-image: linear-gradient(
            to right,
            currentColor var(--value, 0%),
            transparent var(--value, 0%)
        );
    }

    &::-moz-range-track {
        .track-common();
    }

    &::-moz-range-progress {
        height: @progress-slider-height;
        border-radius: @progress-border-radius 0 0 @progress-border-radius;
        background: currentColor;
    }

    &::-ms-track {
        .track-common();
        color: transparent;
    }

    &::-ms-fill-lower, &::-ms-fill-upper {
        .track-common();
    }

    &::-ms-fill-lower {
        background: currentColor;
    }

    &::-ms-tooltip {
        display: none;
    }
}
