.context-menu:not(th) {
    // context-menu needs to be relative for proper dropdown-menu to
    // display in the correct position
    position: relative;
}

.dropdown-toggle {
    > span {
        display: flex;
        align-items: baseline;

        &:after {
            align-self: center;
            margin-left: auto;
            padding-left: 3px;

            font-family : tbfont;
            .kvp(@tbfontglyphmap, menu-down);
            content : @kvp;
        }
    }
}

.dropdown-menu > li > a {
  border-top : 1px solid transparent;
  border-bottom : 1px solid transparent;
}

.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
      border-top : 1px solid black;
      border-bottom : 1px solid black;
  }
}
