@webcall-width : 232px;

@import "fonts/tbfont";
@import "fonts/lato";

@import "bootstrap/variables";
@import "bootstrap/bootstrap";

@import "tb-form-control-focus";

@import "type";
@import "forms";
@import "dropdowns";
@import "print";

@import "components/alerts";
@import "components/datepicker";
@import "components/modals";
@import "components/button-groups";
@import "components/loading-indicator";
@import "components/media-element-player";
@import "components/paging";
@import "components/panels";
@import "components/progress";
@import "components/progress-bars";
@import "components/range-input";
@import "components/tables";
@import "components/toggle";

@import "utility";

@import "button-mapper";

@import "switcher";
@import "portal";
@import "buttons";
@import "lcm-panel";
@import "settings";
@import "report";
@import "wallet";

.pnlWebCallApp {
    display: none;

    @import "webCall/webCallApp";

    @media print {
        display : none !important;
    }

    .modal.mediaError .modal-dialog {
        width : 400px;
    }
}

.pnlWebCallConfApp {
    flex: 0 0 @webcall-width;
    margin-left: auto;

    .view.connecting {
        .status {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 40px;
            margin-top: 5px;

            font-weight: bold;
            font-size: 20px;

            .dots {
                width: 20px;
            }
        }

        .btn {
            width: 100%;
        }
    }

    .disconnected {
        margin-top: 8px;
        text-align: right;
    }
}

.btn-webcall-player {
    display: none;
}

.pnlWebCallPlayer {
    .view {
        &.connecting {
            .status {
                display: inline-block;
                min-width: 130px;
            }
        }

        &.connected {
            .status {
                display: inline-block;
                min-width: 60px;
            }

            .btnDisconnect {
                .tbicon:before {
                    .kvp(@tbfontglyphmap, cross-circle);
                    content : @kvp;
                }
            }
        }
    }
}

.recording-main-container {
    .label {
        font-size: 16px;
        font-weight: bold;
    }

    table {
        width: 100%;

        td {
            padding-bottom: 5px;
            vertical-align: bottom;

            &:not(:first-child) {
                padding-left: 2px;
            }

            &:not(:last-child) {
                padding-right: 2px;
            }
        }
    }

    .downloadLink {
        font-size: 16px;
    }
}

@import "components/loader";
