.hand-raised-icon() {
    .set-glyph(hand);

    &:before {
        font-size: 20px;
        color: @brand-primary;
        font-family: tbfont;
    }
}

#pnlSubpage_lcm {
    .button-mapper(~"button.btnEditReference", comment);

    .gutter-4();

    .in-sub-conf {
        display: none;
    }

    &.state-in-sub-conf {
        .in-main-conf {
            display: none;
        }

        .in-sub-conf {
            display: inherit;
        }
    }

    .lcm-toolbar {
        margin-bottom: 5px;
    }

    .conf-settings {
        width: 100%;

        .dropdown-select {
            .btn {
                width: 100%;
                text-align : left;

                .setting-label {
                    padding-right: 3px;
                    font-weight : bold;
                    color : @brand-primary;
                }
            }

            &.open, button:active:not([disabled]) {
                .setting-label {
                    color : white;
                }
            }
        }
    }

    .conf-pending-start {
        .btn {
            position : absolute;
            right : 8px;
            top : 7px;
        }
    }

    .hand-raising-control {
        table {
            width: 100%;
            table-layout: fixed;

            td {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 2px;

                &:first-child {
                    width: 17.5%;
                }

                &:nth-child(2) {
                    width: 40%;
                }

                &.selection-label {
                    padding-left: 7px;
                }
            }

            .btn-group {
                width: 100%;
            }
        }
    }

    .conf-controls {
        white-space   : nowrap;

        .table-toolbar > * + * {
            margin-left: 3px;
        }

        // left align button text for dropdown buttons
        .btnAction .btn {
            text-align : left;
        }


        .context-menu {
            // workaround for https://github.com/philipwalton/flexbugs#flexbug-1
            // Browsers affected
            // Chrome (fixed in 72)
            // Opera (fixed in 60)
            // Safari (fixed in 10)
            //
            // also fixes the issue for IE11

            flex-shrink: 0;
        }

        .conf-status {
            .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
            vertical-align: middle;
            display: inline-block;
            flex: 0 0 464px;
            border: 1px solid black;
            font-weight: bold;

            &.status-disabled {
                .opacity(.65);
            }

            .status-label {
                display : inline-block;
                color : @brand-primary;
            }

            .status-field {
                display : inline-block;
            }

            .callerCount {
                width : 80px;
            }

            .started {
                width : 144px;
            }

            .duration {
                width : 50px;
            }
        }

        .toolbar-ro {
            display : none;

            .conf-status {
                width : 840px;

                .confMode {
                    width : 100px;
                }

                .duration {
                    width : 80px;
                }

                .tbicon-lock {
                    display : inline-block;
                    vertical-align : top;
                }

                .recordCalls {
                    width: 30px;
                }
            }
        }

        .btn-select-all {
            .dropdown-toggle {
                padding-left : 2px;
                padding-right : 2px;

                > span:after {
                    padding: 0;
                }
            }
        }

        #btnMuteAll {
            flex: 0 0 70px;
        }

        #btnUnmuteAll {
            flex: 0 0 90px;
        }

        .fg-table-search {
            flex-grow: 1;

            .input-group {
                width: 100%;
                .table-search {
                    width: 100%;
                }
            }
        }
    }

    #divCalls {
        position : relative;

        .status-overlays {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;

            // padding covers <thead> so that .panels are vertically
            // centered in <tbody>
            padding-top: (@font-size-base + 12px);

            .panel {
                z-index       : 100;

                min-width: 33%;

                .message-text {
                    font-size : 16px;
                }

                &.connection-error {
                    .btn-retry {
                        display : block;
                        margin-top : 10px;
                    }
                }
            }
        }
    }

    #tblCalls {
        .set-table-row-height(30px);

        &.dimmer {
            .opacity(.65);
        }

        th, td {
            &.callerID {
                width: 180px;
                max-width : 200px;
            }

            &.caller-name {
                width: 150px;
                max-width: 190px;
            }

            &.actionSelect {
                width: 30px;
            }

            &.starred {
                width: 40px;
            }
        }

        .btnStar {
            font-size : 16px;
        }

        // call activity/status

        div.activity {
            font-size : 20px;
            margin : 0 auto;
            width  : 20px;
            color : @brand-primary;

            .tbicon-volume-half, .tbicon-volume-full {
                color : @color-secondary;
            }
        }

        .btnHandRaised {
            .inner {
                display : inline-block;
                margin : 0 auto;

                .hand-raised-icon();

                .order {
                    position:  relative;
                    top : -4px;
                }
            }

            &.selected .inner {
                .set-glyph(hand-selected);
            }
        }

        .hand-raised-label {
            .hand-raised-icon();

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .dropdown-menu {
            min-width : 190px;
        }
    }

    body.view-ro & {
        .conf-pending-start, .hand-raising-control {
            display : none !important;
        }

        .conf-settings {
            display : none !important;
        }

        .conf-controls {
            .toolbar-full {
                display : none;
            }

            .toolbar-ro {
                display : flex;
            }
        }

        .btnMakeCall {
            display : none !important;
        }
    }
}

// modals

#dlgMakeCall {
    .note {
        line-height : 1.2;

        .note-label {
            font-weight : bold;
        }

        &.last {
            margin-top : 15px;
        }
    }
}
