.btnToggle {
    .tbicon();

    &:not(.btn) {
        cursor              : pointer;

        color : lighten(black, 35%);

        &:hover {
            color : black;
        }

        &:disabled {
            cursor : @cursor-disabled;
            color : lighten(black, 70%);
        }
    }

    &:before {
        .kvp(@tbfontglyphmap, checkbox-unchecked);
        content : @kvp;
    }

    &.checked {
        &:before {
            .kvp(@tbfontglyphmap, checkbox-checked);
            content : @kvp;
        }
    }

    &.checked.inverse {
        &:before {
            color : @brand-primary;
            .kvp(@tbfontglyphmap, solid-checkbox-checked);
            content : @kvp;
        }
    }

    &.indeterminate {
        &:before {
            .kvp(@tbfontglyphmap, checkbox-indeterminate);
            content : @kvp;
        }
    }

    &.btnStar {
        &:before {
            .kvp(@tbfontglyphmap, star-unchecked);
            content : @kvp;
        }

        &.checked {
            &:before {
                color : @brand-primary;
                .kvp(@tbfontglyphmap, star-checked);
                content : @kvp;
            }
        }
    }
}
