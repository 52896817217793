@print-header-overlay-height: 40px;

.paging-control {
    .paging-toolbar {
        display: flex;
        justify-content: space-between;

        .paging-toolbar-nav {
            margin-right: 10px;

            .button-mapper(~".btn-first", arrow-double-left);
            .button-mapper(~".btn-last", arrow-double-right);
            .button-mapper(~".btn-prev", arrow-left);
            .button-mapper(~".btn-next", arrow-right);


            > .btn {
                width: 30px;
            }

            > input,
            > div {
                text-align: center;
                width: 40px;
            }

            > div {
                padding: @padding-base-vertical @padding-base-horizontal;
                background-color: @input-group-addon-bg;
                border: 1px solid @input-group-addon-border-color;
            }
        }

        .paging-toolbar-resultCount {
            .btn-group {
                > .btn {
                    width: 60px;
                }
            }
        }
    }

    .print-header-overlay {
        .print-only();

        @media print {
            display : none !important;
        }

        z-index : @zindex-modal;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: @print-header-overlay-height;
        background-color: rgba(0, 0, 0, 0.3);

        .print-header-overlay-inner {
            display: flex;
            justify-content: space-between;
        }
    }

    .paging-control-print-footer {
        .print-only();

        text-align: right;
    }
}
